import React from "react"
import { Section } from "components/SectionList"
import { SOCIAL } from "utils/config"
import css from "./style.module.scss"

const Link = ({ title, url }) => (
  <a className={css.link} rel="noopener noreferrer" href={url} target="_blank">
    {title}
  </a>
)

const Social = () => (
  <Section title="Social & Useful Stuff">
    <div className={css.links}>
      {SOCIAL.map(d => (
        <Link {...d} key={d.title} />
      ))}
    </div>
  </Section>
)

export default Social
