import React from "react"
import Page from "components/Page"
import Title from "components/Title"
import List from "components/List"
import Social from "components/Social"
import BlogItems from "components/BlogItems"
export default ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  return (
    <Page>
      <Title />
      <List name="projects" />
      <List name="experiments" />
      <Social />
      <BlogItems postsData={edges} />
    </Page>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { publish: { eq: true } } }
      limit: 4
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
          }
        }
      }
    }
  }
`
