import React from "react"
import SectionList from "components/SectionList"
import { EXPERIMENTS, PROJECTS } from "utils/config"

const data = {
  experiments: {
    title: "Experiments",
    data: EXPERIMENTS,
  },
  projects: {
    title: "Projects",
    data: PROJECTS,
  },
}

const List = ({ name }) => <SectionList {...data[name]} />

export default List
